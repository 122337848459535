export class SignalIRProcess {
    private connection: signalR.HubConnection;
    private callback: ((message: any) => void) | null;

    constructor() {
        this.callback = null;
        this.connection = new signalR.HubConnectionBuilder()
            .withUrl("/api/process-hub", {
                transport: signalR.HttpTransportType.WebSockets | 
                           signalR.HttpTransportType.ServerSentEvents | 
                           signalR.HttpTransportType.LongPolling
            })
            .configureLogging(signalR.LogLevel.Information)
            .build();
        
        this.connection.on("OnMessage", (message: any, requestId: any) => {
            let parsedMessage = this.tryParseJSON(message);
            if (this.callback) {
                this.callback(parsedMessage);
            }
        });
        
        // this.connection.onclose(async () => {
        //     await this.start();
        // });

    }

    private async start(): Promise<void> {
        try {
        if (this.connection.state !== signalR.HubConnectionState.Connected) {
            
            await this.connection.start();
            console.log("SignalR Connected.");
        }
        } catch (err) {
            console.error("Connection error: ", err);
            setTimeout(() => this.start(), 5000);
        }
    }

    private tryParseJSON(jsonString: any): any {
        try {
            if (jsonString && typeof jsonString === "object") {
                return jsonString;
            }
            let parsed = JSON.parse(jsonString);
            if (parsed && typeof parsed === "object") {
                return parsed;
            }
        } catch (e) {
            console.warn("JSON parse error: ", e);
        }
        return false;
    }

    public async executeSqlOverSignal(vOptions: any, pCallback: (message: any) => void): Promise<void> {
        this.callback = pCallback;
        if (this.connection.state !== signalR.HubConnectionState.Connected) {
            await this.start();
        }
       
        if (!this.callback) {
            console.warn("Message callback is not registered");
        }

        let msg = JSON.stringify(vOptions);
        let subject = new signalR.Subject<string>();
        this.connection.send("ProcessWorkbook", subject).catch(err => console.error("Send error: ", err));

        let messages = this.chunkString(msg, 1000);
        for (let message of messages) {
            subject.next(message);
        }
        subject.complete();
    }

    private chunkString(str: string, size: number = 100): string[] {
        let numChunks = Math.ceil(str.length / size);
        let chunks = new Array<string>(numChunks);

        for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
            chunks[i] = str.substring(o, o + size);
        }

        return chunks;
    }

    public stopConnection(): Promise<void> {
        this.callback = null;
        return this.connection.stop();
    }
}